import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    Title: "Supplier Diversity & Inclusion: Achieve Your Goals With Raindrop",
    NormalPara: "There are two key reasons the year 2020 was transformative for all businesses.",
    PointOne: "Diversity & Inclusion is driving equality into the corporate world.",
    PointTwo: "COVID changed the way the world works, and promoted the digitization of business process fulfillment.",
    DatePara: "Make Diversity a Core Value from the Start. September 08, 2021",
    ImagePath: "https://storage.googleapis.com/raindroppublic/website_data/AchieveYourGoalsEmailRollOut.png",
    AltText: "Enterprise procurement software"
  },
]