import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppResourcesEmailPointBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
`

export const AppResourcesEmailPointBanner = (props: any) => {
  return (
    <AppResourcesEmailPointBannerWapper>
      <div className={props.BannerSection}>
        <div>
          <ReUseHOneTag HeadingStyle={props.BannerHeading} Heading={props.Heading} />
          <ReUsePtag para={props.DatePara} paraStyle={props.DateBannerPara} />
          <ReUsePtag para={props.MainPara} paraStyle={props.BannerPara} />
          <ReUsePtag
                  Icon={props.icon}
                  para={props.SubParaOne}
                  paraStyle={props.BannerParaPoint}
                  IconStyle={props.BannerParaPointIcon}
                />
                <ReUsePtag
                  Icon={props.icon}
                  para={props.SubParaTwo}
                  paraStyle={props.BannerParaPoint}
                  IconStyle={props.BannerParaPointIcon}
                />
        </div>
        <div className={props.BannerImageSection}>
          <ReuseImgTag ImageStyle={props.BannerImage} ImagePath={props.ImagePath} AltName={props.AltText} />
        </div>
      </div>
    </AppResourcesEmailPointBannerWapper>
  )
}
