import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppAchieveYourGoalsRest } from './AppAchieveYourGoalsRest'

const AppAchieveYourGoalsWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .CovidSectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .CovidSectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .CovidSectionSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppAchieveYourGoalsWithNav = () => {
  return (
    <AppAchieveYourGoalsWithNavWapper>
      <div className='CovidSectionSection'>
        <div className='CovidSectionNav'>
          <AppSideNavEmailName />
        </div>
        <div className='CovidSectionSectionContent'>
          <AppAchieveYourGoalsRest />
        </div>
      </div>
    </AppAchieveYourGoalsWithNavWapper>
  )
}
