import React from 'react'
import { AppResourcesEmailPointBanner } from './AppResourcesEmailPointBanner'
import AchieveYourGoalsBannerData from '../Data/AchieveYourGoalsBannerData'
import styled from 'styled-components'

const AppAchieveYourGoalsBannerWapper = styled.div`
  .AchieveYourGoalsSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .AchieveYourGoalsImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .AchieveYourGoalsHeading {
    max-width: 940px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .AchieveYourGoalsPara {
    max-width: 850px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .AchieveYourGoalsParaPoint{
    max-width: 850px;
    padding: 1px 30px 1px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .DateAchieveYourGoalsPara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .AchieveYourGoalsBannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
  }
  .AchieveYourGoalsAnchor {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .AchieveYourGoalsAnchor:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .DisplayNone {
    display: none;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
}
`

export const AppAchieveYourGoalsBanner = () => {
  return (
    <AppAchieveYourGoalsBannerWapper>
      {AchieveYourGoalsBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailPointBanner
              Heading={data.Title}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              MainPara={data.NormalPara}
              SubParaOne={data.PointOne}
              SubParaTwo={data.PointTwo}
              icon={data.icon}
              achorPara='AchieveYourGoalsAnchor'
              BannerHeading='AchieveYourGoalsHeading'
              BannerPara='AchieveYourGoalsPara'
              DateBannerPara='DateAchieveYourGoalsPara'
              BannerImageSection='AchieveYourGoalsImageSection'
              BannerImage='AchieveYourGoalsBannerImage'
              BannerSection='AchieveYourGoalsSection'
              BannerParaPointIcon="iconColor"
              BannerParaPoint="AchieveYourGoalsParaPoint"
            />
          </div>
        )
      })}
    </AppAchieveYourGoalsBannerWapper>
  )
}
