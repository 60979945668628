import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOneLeft: "According to a report by",
    ParaOneRight: "35% of Procurement leadership is considering increasing diversity among suppliers as one of the top Procurement priorities for 2021 and beyond.  Unsurprisingly, whether your company is a SMB, Large Enterprise, or government agency, the criticality to adopt and institutionalize diversity and inclusion into your supply base has grown significantly.",
    ParaOneAnchor: "Small Business TRENDS,",
    ParaNew: "As companies focus on their own internal D&I improvements, there is also the need to focus on their supplier D&I priorities to create a more diverse and inclusive supplier ecosystem supporting their companies.",
    ParaTwo: "Diversity and inclusion are now leading the focus across many Board rooms and conference rooms.  This is where Raindrop’s Supplier Relationship Management (SRM) module comes in which provides 360-degree insights into every contract you have with any supplier, how your suppliers are performing, which industries they reside in, overall performance, risks, continuous improvement plans, how much the supplier has been paid, and much more.  Raindrop’s technology stack is designed with Machine Learning and Artificial Intelligence, enabling diversity and inclusion directly into the procurement business processes fulfillment by highlighting those suppliers in your supplier master list and securing the necessary D&I certifications required by your company.",
    ParaThree: "Raindrop’s Supplier Relationship Management module caters to supporting and addressing critically relevant diversity and inclusion aspects from knowing who your diverse suppliers are, what type of spend is addressed by diverse suppliers, keeping track of supplier certificates, all that way to meeting customer social responsibility goals, and tracking the progress.  Define the action plans, assign the stakeholders, and measure results for every supplier engagement.  With Raindrop, let’s redefine how you interact to realize the most potential in supporting a diverse supplier base. ",
    HeadingOne: "Here are a few key areas how Raindrop’s SRM enhances your company’s Diversity and Inclusion initiatives.",
    SubParaOne: "Deeper knowledge of the supply base helps to innovate your Diversity and Inclusion initiatives to support the long-term supplier relationships required to drive and deliver on D&I goals.",
    SubParaTwo: "Gain a competitive edge and more flexibility when issues such as supply shortages hit. With Raindrop you have complete supplier visibility across spend categories and can shift to alternate suppliers instantly.  With a click to identify your Diversity and Inclusion suppliers, your company is better-placed for the long run.",
    SubParaThree: "Streamline your supplier governance function by leveraging Raindrop’s process automation capabilities. Raindrop keeps track of all Diverse and Inclusive supplier certificates, with the functionality to automatically remind both the supplier and the internal stakeholder when the supplier’s certificate nears expiration.",
    SubParaFour: "Set and monitor your D&I program targets.  More and more companies are allocating funds to spend with Diverse certified suppliers.  Raindrop provides an intuitive dashboard where you can quickly see your diverse suppliers and monitor/track to meet your corporate D&I targets.",
    SubParaFive: "Raindrop’s ability to support and drive a diverse supplier network will ensure your Diversity & Inclusion initiatives are met.  In turn, companies can now tie corporate top-level D&I strategies back to the various functional groups in a consistent manner and message to all stakeholders.  Raindrop is a highly collaborative platform, enabling all internal groups to roll up to top-level corporate D&I initiatives.",
    SubParaSix: "Supplier diversity and inclusion encourages innovation which gives businesses a distinct competitive advantage in an increasingly competitive world.  With Raindrop, you can plan, operationalize, and deliver on your Diversity & Inclusion goals and help your company grow.",
    ShiftUrlHref: "https://smallbiztrends.com/2021/06/increasing-supplier-diversity.html",
  },
]
